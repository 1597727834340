import * as React from "react"

export function Footer() {
  return (
    <footer>
      Copyright © {new Date().getFullYear()}{" "}
      <a href="https://fullstackcraft.com">Full Stack Craft</a>
    </footer>
  )
}
